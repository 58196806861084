<template>
  <form class="chat-widget__form" @submit.prevent="submitForm">
    <button
      class="chat-widget__mic-button"
      :disabled="isSending"
      type="button"
      @click="toggleAudioCapture"
    >
      <i class="fa fa-microphone"></i>
      <div v-show="isRecording" class="chat-widget__recording-indicator"></div>
    </button>
    <textarea
      ref="textarea"
      v-model="newMessageText"
      class="chat-widget__input"
      placeholder="Type your message here..."
      @input="adjustTextareaHeight"
      @keydown="handleKeyDown"
    ></textarea>
    <button class="chat-widget__button" type="submit">
      <i v-if="!isSending" alt="" class="fa fa-paper-plane"></i>
      <img v-else alt="" class="stop-square" :src="StopButton" />
    </button>
  </form>
</template>

<script>
import { COPILOT_GETTERS } from '@src/shared/store/modules/copilot';
import StopButton from '@shared/assets/images/stop.svg';
import { mapGetters } from 'vuex';

export default {
  name: 'ChatInput',
  components: {
    StopButton,
  },
  data() {
    return {
      newMessageText: '',
      isRecording: false,
      mediaRecorder: null,
      recordingTimeoutId: null,
    };
  },
  computed: {
    ...mapGetters({
      isSending: `copilot/${COPILOT_GETTERS.isSending}`,
    }),
  },
  methods: {
    adjustTextareaHeight() {
      const textarea = this.$refs.textarea;
      textarea.style.height = 'auto';
      const height = Math.min(textarea.scrollHeight, 100);
      if (height > textarea.clientHeight && height >= 50) {
        textarea.style.height = `${height}px`;
      } else {
        textarea.style.height = '50px';
      }

      if (this.newMessageText === '') {
        textarea.style.height = '50px';
      }
    },
    handleKeyDown(e) {
      const enterKey = 13;
      if (e.keyCode === enterKey && !e.shiftKey) {
        e.preventDefault();

        if (!this.isSending) {
          this.submitForm();
        }
      }
    },
    submitForm() {
      if (this.isSending) {
        // This means that the user clicked the stop button and we should abort active requests
        this.$nliService.handleStopCopilotResponse();
        return;
      }
      this.$nliService.sendMessage(this.newMessageText);
      this.newMessageText = '';
      this.adjustTextareaHeight();
    },
    transcribeAudio(audioBase64) {
      this.$nliService.sendAudioMessage(audioBase64);
    },
    toggleAudioCapture() {
      if (!this.isRecording) {
        this.startAudioCapture();
      } else {
        this.stopAudioCapture();
      }
    },
    stopAudioCapture() {
      // Add logic to stop audio capture
      if (this.mediaRecorder) {
        this.mediaRecorder.stop();
        // Add this line to get the MediaStream and stop each track
        this.mediaRecorder.stream.getTracks().forEach(track => track.stop());
        this.cleanupRecording();
      }
    },
    startAudioCapture() {
      if (navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then(stream => {
            this.mediaRecorder = new MediaRecorder(stream);
            const audioChunks = [];
            this.isRecording = true; // Set recording state to true

            this.mediaRecorder.ondataavailable = event => {
              audioChunks.push(event.data);
            };

            this.mediaRecorder.start();

            // Function to stop recording
            const stopRecording = () => {
              this.mediaRecorder.stop();
              stream.getTracks().forEach(track => track.stop());
              this.isRecording = false; // Set recording state to false
            };

            // Automatically stop recording after a certain time or by user action
            this.recordingTimeoutId = setTimeout(stopRecording, 60000);

            this.mediaRecorder.onstop = async () => {
              const audioBlob = new Blob(audioChunks);
              const audioBase64 = await this.convertAudioBlobToBase64(
                audioBlob,
              );
              this.transcribeAudio(audioBase64);
            };
          })
          .catch(error => {
            this.cleanupRecording();
            console.error('Error accessing the microphone', error);
          });
      } else {
        console.log('Browser does not support getUserMedia API');
      }
    },
    cleanupRecording() {
      this.isRecording = false;
      if (this.recordingTimeoutId) {
        clearTimeout(this.recordingTimeoutId);
        this.recordingTimeoutId = null;
      }
    },
    convertAudioBlobToBase64(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64data = reader.result.split(',')[1];
          resolve(base64data);
        };
        reader.onerror = error => reject(error);
      });
    },
  },
};
</script>

<style lang="scss">
@import '@shared/styles/variables.scss';

.chat-widget__form {
  /* remove absolute positioning */
  display: flex;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
}

.chat-widget__input {
  flex: 1;
  padding: 15px;
  border: 1px solid #ccc;
  border-left: transparent;
  outline: none;
  resize: none;
  overflow: hidden;
  word-wrap: break-word;
  height: 50px;
  max-height: 100px;
  overflow-y: auto;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.chat-widget__input:focus {
  border-top: 2px solid $polly-light-blue;
}

.chat-widget__button {
  padding: 5px 20px;
  background-color: $polly-light-blue;
  color: #fff;
  border: none;
  cursor: pointer;
}

.chat-message-box {
  display: flex;
  align-items: flex-start;
}

.chat-widget__mic-button {
  position: relative; /* Ensure the element's position is set to allow absolute children positioning */
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-left: transparent;
  border-right: transparent;
  background-color: transparent;
  font-size: 24px;
  cursor: pointer;
  padding-left: 15px;
  padding-right: 10px;
  color: #48aff0; /* Polly light blue color for the icon */
  &:hover {
    background-color: #e1f3fb; /* Light blue background on hover */
  }
}

.fa-microphone {
  color: #48aff0; /* Polly light blue color for the icon */
}

.chat-widget__mic-button:disabled {
  opacity: 0.5;
}

.stop-square {
  width: 13px;
  height: 13px;
  background-color: white;
  border-radius: 2px;
}

.chat-widget__recording-indicator {
  /* Modify the style to represent a top border instead of a dot */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 2px; /* Adjust the height to represent a top line instead of a dot */
  background: red; /* Set the color for the recording indicator to red */
  display: block;
  /* Reuse the blink-animation keyframes defined in the style */
  animation: blink-animation 1s steps(5, start) infinite;
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
</style>
